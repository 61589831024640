import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faMapMarker, faEnvelopeOpen, faLink, faStar, faQuoteLeft, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faLinkedin, faGithubAlt } from '@fortawesome/free-brands-svg-icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Media, Nav, Navbar, Row } from 'react-bootstrap';
import GitHubFeed from 'react-github-activity';
import axios from 'axios';
//import DefaultRSSComponent from './default-RSS-component';




function Stars(num: number) : any[] {

  const stars = [];
  for (let i = 0; i < num; i++) {
    stars.push(<FontAwesomeIcon key={"star_" + i} icon={faStar} className="fa"></FontAwesomeIcon>)
  }

  return stars;
}

type SkillBarProps = {
  percentage: number
};

const SkillBar = ({
    percentage
  } : SkillBarProps
  ) => {
  const level = `${percentage}%`;
  return (
    <div className="level-bar">
      <div className="level-bar-inner" style={{width : level}}>
        <span className="progress"></span>
      </div>
    </div>
  );
};

type SkillItemProps = {
  percentage: number,
  skillName: string,
  title?: string,
};

function getSkillLevel(percentage : number) {
  const level = percentage / 20;
  switch(level) {
    case 1:
      return "Novice";
    case 2:
      return "Beginner";
    case 3:
      return "Skillfull";
    case 4:
      return "Experienced";
    case 5:
      return "Expert";
    default:
      return "Invalid";
  }
}

const SkillItem = ({
  percentage,
  skillName,
  title,
} : SkillItemProps) => {

  const skillLevel = getSkillLevel(percentage);
  const titleName = title ? title : skillName;

  return (
    <div className="item">
        <h3 className="level-title">
          {skillName}
          <span className="level-label"
                data-toggle="tooltip"
                data-placement="left"
                data-animation="true"
                data-title={titleName}>{skillLevel}</span>
        </h3>

        <SkillBar percentage={percentage}></SkillBar>
    </div>
  );
};

export function MyCv() {

  const fullName = 'Julian Quiroga';
  const userName = 'jgquiroga';
  const avatarUrl = 'https://avatars.githubusercontent.com/u/3643834?v=3';
  const userApiUrl = `https://api.github.com/users/${userName}/events`;

  const stringArrayVoid : string [] = [];
  const [events, setEvents] = useState(stringArrayVoid);
  // const rss1 = {
  //   link : "http://julianquiroga.blogspot.com/feeds/posts/default",
  // };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get<string []>(userApiUrl);
      setEvents(result.data);
      
    };
    fetchData();
  }, [userApiUrl]);

  return (
    <>
      <Navbar bg="dark" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand style={{ paddingLeft : "15px" }} href="/">Julián Quiroga</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <header className="header padding-menu">
        <Container>
          <Media>
            <img className="profile-image img-responsive float-left" src="images/profile.png" alt="Julián Quiroga" />
            <Media.Body>
              <div className="profile-content float-left">
                <h1 className="name">Julián Quiroga</h1>
                <h2 className="desc">Software Architect</h2>
                <ul className="social list-inline">
                    <li className="list-inline-item"><a href="https://twitter.com/JulianGQuiroga"><FontAwesomeIcon icon={faTwitter} className="media-icons"></FontAwesomeIcon></a></li>
                    <li className="list-inline-item"><a href="https://www.linkedin.com/in/juli%C3%A1n-quiroga-4992916/"><FontAwesomeIcon icon={faLinkedin} className="media-icons"></FontAwesomeIcon></a></li>
                    <li className="list-inline-item"><a href="https://github.com/jgquiroga"><FontAwesomeIcon icon={faGithubAlt} className="media-icons"></FontAwesomeIcon></a></li>
                </ul>
              </div>
              <a className="btn btn-cta-primary float-right" href="mailto:jgquiroga@gmail.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon> Contact Me
              </a>

            </Media.Body>
          </Media>




        </Container>
      </header>

      <Container className="sections-wrapper">
        <Row>
          <Col className="primary" md={8} sm={12} xs={12}>
            <section className="about section">
              <div className="section-inner">
                <h2 className="heading">Profile</h2>
                <div className="content">
                  <p>Experienced Software Architect with 10+ years of experience in designing, developing and testing high-performance Software Applications.</p>
<p>Proficient in various platforms, languages, and technologies.</p>
<p>Adept in dealing with complex issues and challenges, finding new ways to
improve the team.</p>
                </div>
              </div>
            </section>

            <section className="experience section">
              <div className="section-inner">
                <h2 className="heading">Employment History</h2>
                <div className="content">
                  <div className="item">
                    <h3 className="title">Software Architect - <span className="place"><a href="https://www.charlestaylor.com/es/insuretech/">Charles Taylor InsureTech, Buenos Aires</a></span> <span className="year">(April 2016 - Present)</span></h3>
                    <p>Working as a Software Architect for projects in Argentina and UK.</p>
                    <p>My tasks include:</p>
                    <ul>
                      <li>English meetings with Business Analysts and stakeholders.</li>
                      <li>Design of software Integrations for on premises or cloud solutions.</li>
                      <li>Code review and pull request reviews.</li>
                      <li>Investigation of new SaaS/PaaS solutions and new frameworks.</li>
                    </ul>
                    <p>Azure Skills:</p>

                    <ul>
                      <li>Multitenant Integration with Azure Active Directory</li>
                      <li>CosmosDB administration (Design of Collections, tunning of Queries, creation of functions and procedures, Change Feed processing).</li>
                      <li>Use of storages (Blob storage and Queues).</li>
                      <li>Web application deployment and configuration.</li>
                      <li>Azure Function deployment and configuration.</li>
                    </ul>
                    <p>Other Skills:</p>
                    <ul>
                      <li>C#, asp .net core, JavaScript, Typescript, HTML, SCSS, Angular 8, React, TDD and Unit testing.</li>
                    </ul>
                  </div>
                  <div className="item">
                    <h3 className="title">Engineering Specialist - <span className="place"><a href="https://www.charlestaylor.com/es/insuretech/">Charles Taylor InsureTech, Buenos Aires</a></span> <span className="year">(June 2009 - April 2016)</span></h3>
                    <ul>
                      <li>Worked on the engineer team in the definition and creation of the framework being used in insurance products.</li>
                      <li>Development of a Service Oriented Architecture made in .Net using modules with AOP support for Logging, Error handling and Security.</li>
                      <li>Utilized excellent development and investigation skills to increase the performance of high demanding processes to manage high concurrency and high availability.</li>
                      <li>Experience in: C#, Sprint .Net, NHibernate .net, NUnit, Moq, Reflector, Ants profiler, SoapUI, VS 2013-2015 Stress and Load Testing tools.</li>
                    </ul>
                  </div>
                  <div className="item">
                      <h3 className="title">Software Developer - <span className="place"><a href="https://www.capgemini.com/ar-es/">Capgemini</a></span> <span className="year">(November 2008 - June 2009)</span></h3>
                      <ul>
                        <li>Worked in a Service Oriented Architecture developing modules for an Adecco France site.</li>
                        <li>Tools used: Spring.Net, NHibernate, NUnit, Moq.</li>
                      </ul>
                  </div>

                  <div className="item">
                      <h3 className="title">Programmer Analyst - <span className="place"><a href="https://www.hpe.com/us/en/services/consulting.html#">Hewlett Packard (ex EDS)</a></span> <span className="year">(April 2007 - November 2008)</span></h3>
                      <ul>
                        <li>
                          Analysis and Development of workflow tools and solutions for Ternium Siderar.
                        </li>
                        <li>
                          Tools used: C#, VBScript, JQuery
                        </li>
                      </ul>
                  </div>
                </div>
              </div>
            </section>

            <section className="github section">
              <div className="section-inner">
                <h2 className="heading">My GitHub</h2>
                <GitHubFeed
                    fullName={fullName} // Provide Full Name as displayed on GitHub
                    userName={userName} // Provide User Name as displayed on Guthub
                    avatarUrl={avatarUrl} // Provide the avatar url of your github profile
                    events={events} // provide array of events using the users '/events' endpoint of github api
                    >

                </GitHubFeed>
              </div>
            </section>


          </Col>

          <Col className="secondary" md={4} sm={12} xs={12}>
            <aside className="info aside section">
              <div className="section-inner">
                <h2 className="heading sr-only">Basic Information</h2>
                <div className="content">
                    <ul className="list-unstyled">
                        <li><FontAwesomeIcon icon={faMapMarker} className="fa"></FontAwesomeIcon><span className="sr-only">Location:</span>Ciudad de Buenos Aires, AR</li>
                        <li><FontAwesomeIcon icon={faEnvelopeOpen} className="fa"></FontAwesomeIcon><span className="sr-only">Email:</span><a href="mailto:jgquiroga@gmail.com">jgquiroga@gmail.com</a></li>
                        <li><FontAwesomeIcon icon={faLink} className="fa"></FontAwesomeIcon><span className="sr-only">Blog .Net:</span><a href="http://blog.jgqsolutions.com.ar">http://blog.jgqsolutions.com.ar</a></li>
                        <li><FontAwesomeIcon icon={faLink} className="fa"></FontAwesomeIcon><span className="sr-only">Blog Pics y Arduino:</span><a href="http://programandopics.blogspot.com.ar">http://programandopics.blogspot.com.ar</a></li>
                    </ul>
                </div>
              </div>
            </aside>

            <aside className="skills aside section">
              <div className="section-inner">
                <h2 className="heading">
                  Skills
                </h2>
                <div className="content">
                  <p className="intro">
                      Programación de sistemas complejos independientemente de la tecnología e Investigación de nuevos Frameworks.
                  </p>

                  <div className="skillset">
                    <SkillItem skillName="Microservices" percentage={100} ></SkillItem>
                    <SkillItem skillName="C#" percentage={100} ></SkillItem>
                    <SkillItem skillName="Angular/AngularJs" percentage={100} ></SkillItem>
                    <SkillItem skillName="Javascript/Typescript" percentage={100} ></SkillItem>
                    <SkillItem skillName="SQL" percentage={100} ></SkillItem>
                    <SkillItem skillName="NHibernate" percentage={100} ></SkillItem>
                    <SkillItem skillName="Microsoft Azure" percentage={80} ></SkillItem>
                    <SkillItem skillName="Docker/Kubernetes" percentage={60} ></SkillItem>
                    <SkillItem skillName="Python" percentage={60} ></SkillItem>
                    <SkillItem skillName="React" percentage={60} ></SkillItem>
                    
                </div>


                </div>
              </div>
            </aside>

            <aside className="testimonials aside section">
                    <div className="section-inner">
                        <h2 className="heading">Testimonials</h2>
                        <div className="content">
                            <div className="item">
                                <blockquote className="quote">
                                    <p><FontAwesomeIcon icon={faQuoteLeft} className="fa"></FontAwesomeIcon> Julian is an excellent professional, not only for his capabilities but also personally he is very friendly and engaged with everything he does.</p>

<p>I enjoyed working with Julian, he is someone you can trust. When he puts some ideas over the table to argue about the best solution for a problem, you can be sure he analyzed the problem enough to get that solution.</p>

<p>I really recommend Julian for being part of the staff if you are thinking of a great team.</p>
                                </blockquote>
                                <p className="source"><span className="name">Emmanuel Teodoro</span><br /><span className="title">Presales Solutions Engineer at MuleSoft Inc</span></p>
                            </div>

                            <p><a className="more-link" href="https://www.linkedin.com/in/juli%C3%A1n-quiroga-4992916/"><FontAwesomeIcon icon={faExternalLinkAlt} className="fa"></FontAwesomeIcon> More on Linkedin</a></p>

                        </div>
                    </div>
                </aside>

                <aside className="education aside section">
                    <div className="section-inner">
                        <h2 className="heading">Education</h2>
                        <div className="content">
                            <div className="item">
                                <h3 className="title"><i className="fa fa-graduation-cap"></i> Bachelor's degree in Computer Science</h3>
                                <h4 className="university">Universidad Argentina de la Empresa, Buenos Aires <span className="year">(2005-2009)</span></h4>
                            </div>
                        </div>
                    </div>
                </aside>

                <aside className="languages aside section">
                    <div className="section-inner">
                        <h2 className="heading">Languages</h2>
                        <div className="content">
                            <ul className="list-unstyled">
                                <li className="item">
                                    <span className="title"><strong>Spanish:</strong></span>
                                    <span className="level">&nbsp; Native Speaker <br className="visible-xs"/>{Stars(5)}</span>
                                </li>
                                <li className="item">
                                    <span className="title"><strong>English:</strong></span>
                                    <span className="level">&nbsp; Professional Basic <br className="visible-sm visible-xs"/>{Stars(3)}</span>
                                </li>
                                <li className="item">
                                    <span className="title"><strong>Korean:</strong></span>
                                    <span className="level">&nbsp; Basic <br className="visible-sm visible-xs"/>{Stars(1)}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </aside>

                <aside className="blog aside section">
                    <div className="section-inner">
                        <h2 className="heading">Latest Blog Posts</h2>
                        <div id="rss-feeds" className="content">
                        {/* <DefaultRSSComponent
                            label="Default"
                        /> */}
                        </div>
                    </div>
                </aside>


          </Col>


        </Row>
      </Container>

      {/* ******FOOTER****** */}
      <footer className="footer">
        <Container className="text-center">
        <small className="copyright">Copyright @ 2021 JGQ Solutions - Designed with <i className="fa fa-heart"></i> by <a href="http://themes.3rdwavemedia.com" target="_blank" rel="noopener noreferrer">Xiaoying Riley</a> for developers</small>
        </Container>
      </footer>
    </>
  );
}
