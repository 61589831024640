import React, { useEffect, useState } from 'react';
import "../css/k-covers-descriptions.css";
import { Container, Form, Jumbotron, Nav, Navbar } from 'react-bootstrap';
import GoogleLogin, { GoogleLogout } from 'react-google-login';


export function KCoversDescriptions() {
    const initialToken = sessionStorage.getItem("currentToken");
    const initialTokenExpiration = sessionStorage.getItem("currentTokenExpiration");
    const initialIsLoggedIn = 
        initialToken !== "" && initialToken !== undefined && initialToken !== null &&
        initialTokenExpiration !== "" && initialTokenExpiration !== undefined && initialTokenExpiration !== null &&
        // Not expired
        new Date(parseInt(initialTokenExpiration)) < new Date();
    

    const [currentToken, setCurrentToken] = useState(initialToken);
    const [currentTokenExpiration, setCurrentTokenExpiration] = useState(initialTokenExpiration);

    const [isLoggedIn, setIsLoggedIn] = useState(initialIsLoggedIn);

    const clientId = "806130809778-137s5eulucgm5folre5gnicj5oflf49u.apps.googleusercontent.com";

    const success = (response: any) => {
        console.log("Login Succesfull"); // eslint-disable-line
        console.log(response); // eslint-disable-line
        const tokenId = response.tokenId;
        const expires_at = response.tokenObj.expires_at.toString();
        console.log(tokenId); // eslint-disable-line
        sessionStorage.setItem("currentToken", tokenId);
        sessionStorage.setItem("currentTokenExpiration", expires_at);
        setCurrentToken(tokenId);
        setCurrentTokenExpiration(expires_at);
        setIsLoggedIn(true);
    };

    const logoutSuccess  = () => {
        sessionStorage.setItem("currentToken", "");
        sessionStorage.setItem("currentTokenExpiration", "");
        setCurrentToken("");
        setCurrentTokenExpiration("");
        setIsLoggedIn(false);
    };

    // Only Call once (and needs to be executed without conditions)
    useEffect(() => {

        if (!currentToken || !currentTokenExpiration) {
            return;
        }

        // fetch(`${process.env.REACT_APP_API_URL??""}/api/message`, {
        //     headers: {
        //         'Authorization': 'Bearer ' + currentToken,
        //         'x-custom-authorization': 'Bearer ' + currentToken,
        //     }
        // })
        // .then(result => {
        //     console.debug(result.body);
        // });

    }, [currentToken, currentTokenExpiration]);

    if (!isLoggedIn) {
        return (
            <GoogleLogin
                clientId={clientId}
                buttonText="Login"
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
                onSuccess={res => {
                    setIsLoggedIn(true);
                    success(res);
                }}>

            </GoogleLogin>
        );
    }

    return (
        <>
              <Navbar bg="dark" variant="dark" fixed="top">
                <Container>
                <Navbar.Brand style={{ paddingLeft : "15px" }} href="/k-covers-descriptions">K-Covers</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/k-covers-descriptions">Home</Nav.Link>
                    </Nav>
                    <Nav className="mr-auto">
                        <GoogleLogout clientId={clientId} buttonText="Logout" onLogoutSuccess={logoutSuccess}>

                        </GoogleLogout>
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>

            <Container>

                <Jumbotron>
                    <h1>K-covers</h1>
                    <p className="lead">Generador de Descripciones</p>
                    <p className="lead">Escribir los datos del cover.</p>
                    <p className="lead">Seleccionar los participantes</p>
                    <p className="lead">quién hizo la adaptación, el audio y video.</p>
                    <p className="lead">Hacer click en generar y luego copiar el resultado en la descripción del video.</p>
                </Jumbotron>

                <Form>
                    <Form.Group controlId="artistName">
                        <Form.Label>Nombre del artista</Form.Label>
                        <Form.Control type="text" placeholder="Nombre del artista"></Form.Control>
                        <Form.Text className="text-muted">
                            Ingrese el nombre del artista
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="songName">
                        <Form.Label>Nombre de la canción</Form.Label>
                        <Form.Control type="text" placeholder="Nombre de la canción"></Form.Control>
                        <Form.Text className="text-muted">
                            Ingrese el nombre de la canción
                        </Form.Text>
                    </Form.Group>
                </Form>

            </Container>


        </>
    );
}