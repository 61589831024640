import React from 'react';
import Switch from 'react-bootstrap/esm/Switch';
import {
  BrowserRouter as Router, Route
} from 'react-router-dom'
import { KCoversDescriptions } from './k-covers-descriptions/KcoversDescriptions';

import { MyCv } from './my-cv/MyCv';

function App () {
  const api_regex = /^\/api\/.*/;
  // if using "/api/" in the pathname, don't use React Router
  if (api_regex.test(window.location.pathname)) {
    return (
      <></>
    ); // must return at least an empty component
  }

  return (
    <Router>
      <Switch>
      <Route exact path="/">
          <MyCv />
        </Route>
        <Route path="/my-cv">
          <MyCv />
        </Route>
        <Route path="/k-covers-descriptions">
          <KCoversDescriptions />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
